/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminLoggedOut } from "../auth/authSlice";
import { api_url } from "src/config";

const baseQuery = fetchBaseQuery({
  baseUrl: api_url,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const token = getState()?.auth?.accessToken;
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",

  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // if net is disconnect then log out using  this string --> "FETCH_ERROR" => optional

    if (result?.error?.status === 401) {
      api.dispatch(adminLoggedOut());
    }

    return result;
  },

  tagTypes: [],
  endpoints: () => ({}),
});
